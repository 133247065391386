<template>
  <div class="onboarding-form">
    <content-loader
      v-if="dataLoading == true"
      viewBox="0 0 476 124"
      primaryColor="#f3f3f3"
      secondaryColor="#cccccc"
    >
      <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
      <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
      <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      <circle cx="20" cy="20" r="20" />
    </content-loader>
    <div v-if="!dataLoading == true" class="pb-5 pt-lg-0 pt-3">
      <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        Observation Classes <small>(Step 7 of 10)</small>
      </h3>
      <h4 class="pb-5">
        A preamble of observation classifications here ...
      </h4>
      <v-card elevation="2" outlined>
        <form
          @submit.prevent="submit"
          class="form px-4 pt-4"
          autocomplete="false"
        >
          <div class="form-row">
            <div class="col-sm-12 col-md-4">
              <input
                type="text"
                class="form-control"
                placeholder="Observation class"
                :class="{ 'is-invalid': $v.name.$error }"
                v-model.trim="$v.name.$model"
              />
              <template v-if="$v.name.$error">
                <span class="form-text text-danger" v-if="!$v.name.required">
                  observation class is required.
                </span>
              </template>
            </div>
            <div class="col-sm-12 col-md-7">
              <input
                type="text"
                class="form-control"
                placeholder="Description"
                :class="{ 'is-invalid': $v.description.$error }"
                v-model.trim="$v.description.$model"
              />
              <template v-if="$v.description.$error">
                <span
                  class="form-text text-danger"
                  v-if="!$v.description.required"
                >
                  observation description is required.
                </span>
              </template>
            </div>
            <div class="col-sm-12 col-md-1">
              <button
                :disabled="loading == true"
                type="submit"
                class="btn btn-primary font-weight-bolder btn-block"
              >
                <b-spinner
                  small
                  v-if="loading == true"
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <span v-if="loading == false">ADD</span>
              </button>
            </div>
          </div>
        </form>
        <hr />
        <template>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="classifications"
            v-bind:loading="loading"
            loading-text="Loading..."
            :items-per-page="5"
          >
            <template v-slot:[`item.id`]="{ item }">
              <b-button
                class="btn-xs float-right"
                variant="danger"
                @click="deleteItem(item.id)"
                ><i class="fas fa-trash pr-0"></i
              ></b-button>
            </template>
          </v-data-table>
        </template>
      </v-card>

      <div class="pb-lg-0 pb-5 float-right mt-5">
        <router-link to="/onboarding/waste-streams" v-slot="{ navigate }">
          <button
            type="button"
            class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
            @click="navigate"
          >
            <i class="fas fa-arrow-left ml-2"></i>
            Back
          </button>
        </router-link>

        <button
          type="button"
          class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
          @click="nextStep()"
        >
          <b-spinner
            v-if="loading == true"
            variant="light"
            label="Spinning"
          ></b-spinner>
          <span v-if="loading == false"
            >Next<i class="fas fa-arrow-right ml-2"></i
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Swal from "sweetalert2";
import CompanyService from "@/core/services/company.service.js";
import { ContentLoader } from "vue-content-loader";
import ObservationClassificationService from "@/core/services/observation-classification.service.js";
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { UPDATE_AUTH } from "@/core/services/store/auth.module";
export default {
  data() {
    return {
      name: "",
      description: "",
      classifications: [],
      headers: [
        {
          text: "Classification",
          align: "start",
          sortable: true,
          value: "name"
        },
        { text: "Description", value: "description", sortable: false },
        { text: "Action", value: "id", align: "end", sortable: false }
      ],
      loading: false,
      dataLoading: true
    };
  },
  validations: {
    name: { required },
    description: { required }
  },
  components: {
    ContentLoader
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"])
  },
  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      ObservationClassificationService.all()
        .then(response => {
          this.classifications = response.data;
          this.dataLoading = false;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate classifications at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },

    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;

        ObservationClassificationService.create({
          name: this.name,
          description: this.description
        })
          .then(response => {
            console.log(response);
            this.getList();
            this.loading = false;
            this.name = "";
            this.description = "";
            this.$nextTick(() => {
              this.$v.$reset();
            });
            this.makeToast(
              "success",
              "Successful",
              "Observation class was succesfully added"
            );
          })
          .catch(error => {
            if (error.status == 422) {
              let errors = Object.values(error.data.errors);
              errors = errors.flat();
              this.makeToast("danger", "Validation Error", errors[0]);
            } else if (error.status == 400) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a minute and refresh page"
              );
            }
            this.loading = false;
            console.log(error);
          });
      }
    },

    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this item",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return ObservationClassificationService.delete(id)
            .then(response => {
              this.getList();
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            "Observation Class Deleted",
            "Observation Class was successfully deleted"
          );
        }
      });
    },

    nextStep() {
      if (this.classifications.length == 0) {
        this.makeToast(
          "danger",
          "Validation Error",
          "You are required to add at least one observation class"
        );
        return;
      }

      this.loading = true;
      var data = {
        previous_onboarding_stage: 7,
        onboarding_stage: 8,
        onboarding_complete: false
      };
      CompanyService.setOnboardingStage(data)
        .then(response => {
          try {
            this.$store.dispatch(UPDATE_AUTH, response.data);
            this.$router.push({ name: "onboarding-choose-action-party" });
          } catch (error) {
            console.log(error);
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
            this.loading = false;
          }
        })
        .catch(error => {
          if (error.data) {
            this.makeToast("danger", error.statusText, error.data.message);
          } else {
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
          }
          this.loading = false;
        });
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
